<template>
<div>Other Qualifications</div>
</template>

<script>
import {mapState} from "vuex";
import {isAuthorised} from "@/util/helpers";

export default {
  name: "OtherQualificationsPage",
  computed: {
    ...mapState("person",["activePerson","loggedInPerson"]),
    ...mapState("user", ["accountRoles"]),
    isEditable() {
      return this.activePerson._id === this.loggedInPerson._id || isAuthorised(this.accountRoles,["rootAdmin","admin"])
    }
  },
}
</script>

<style scoped>

</style>